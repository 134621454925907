// const senderNameStyle = 'font-size: 14pt; font-family: Arial; color: rgb(127,127,127); background-color: transparent; font-weight: 700; vertical-align: baseline; white-space: pre-wrap;';
const sendRoleStyle = 'font-size: 9pt; font-family: Arial; color: rgb(58,180,75); background-color: transparent; font-weight: 700; vertical-align: baseline; white-space: pre-wrap;';

export const getEmailContent = (firstName) => {
  return `
  <div>
    <div>Dear ${firstName || 'donor'},</div>

    <div style="margin-top: 15px;">On behalf of all the Team of BirdCare Aotearoa, a big "Thank You" for your generosity and support to our wild birds.</div>
    
    <div style="margin-top: 15px;">Please find attached a Donation Tax Receipt.</div>

    <div style="margin-top: 15px;">Kind regards,</div>
    <div style="margin-top: 50px;">
      <img style="margin-right: 50px; float: left; height: 124px; width: 124px; display: inline-block;" src="https://ecp.yusercontent.com/mail?url=https%3A%2F%2Flh6.googleusercontent.com%2Fz6pqPIFZcgpbSQ5kDm6x6DJky6pH989STTEurTaI2K_6WJAawzrWgpWf4tSofNwpwTFi3FdPmdOjVtlJqSmFELVMLTt4U-kWAa15mI8Bibsi_XarhCkUvtXQL6fkZV4uIX3dyDDo%3Ds0&t=1653976232&ymreqid=ed16d66b-70d1-ae02-1c0e-270003014100&sig=1IOle.OXlRPE8WPLA7SMYQ--~D" />
      <div>
        <div style="${sendRoleStyle}">ADMINISTRATION MANAGER</div>
        <div><a href="admin@birdcareaotearoa.org.nz">admin@birdcareaotearoa.org.nz</a></div>
        <div>74 Avonleigh Road, Green Bay, Auckland 0604</div>
        <div>
          <a href="www.birdcareaotearoa.org.nz">www.birdcareaotearoa.org.nz</a>
          <a href="www.facebook.com/BirdCareAotearoa">www.facebook.com/BirdCareAotearoa</a>
        </div>
      </div>
    </div>

    <div style="margin-top: 45px; font-size: 11px">
      <div>Did you know your donations to BirdCare Aotearoa are eligible for a 33% tax rebate?</div>
      <div>It’s easy to claim your rebate and you can choose to increase your impact by donating it back to BirdCare Aotearoa by signing up to Supergenerous at:</div>
      <div><a href="https://www.supergenerous.co.nz/superpartners/birdcare-aotearoa">https://www.supergenerous.co.nz/superpartners/birdcare-aotearoa</a>
      <div><i>Supergenerous is approved by the IRD as a Tax Agent - for the purpose of making donation tax claims on behalf of individuals in New Zealand</i></div>
    </div>
  </div>
  `
};
